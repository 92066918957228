/**actions required for user list api */

import { ErrorInAPI, SortAndPaginationModal } from '@models/common.model';
import { GetLoadDetail, LoadById, LoadItem, LoadListResponse, LoadListSearch, ParamUpdateLoad } from '@models/loads.model';
import { createAction, props } from '@ngrx/store';


export const loadLoads = createAction(
  '[API] Get Loads',
  props<{
    pagination: SortAndPaginationModal, search: string;
  }>()
);

export const loadLoadWithSearch = createAction('[API]Load Loads with search',
  props<{ pagination: SortAndPaginationModal, search: string, filter?: LoadListSearch | {} }>());

export const loadLoadSuccess = createAction(
  '[API] Get Loads Success',
  props<{ loads: LoadListResponse }>()
);

export const loadLoadError = createAction(
  '[API] Get Loads Error',
  props<{ error: ErrorInAPI }>()
);

export const setActiveTab = createAction(
  '[API]Set Load Active Tabs',
  props<{ tab: number }>()
);


export const getLoadDetail = createAction(
  '[API] Get Load Detail',
  props<GetLoadDetail>()
);

export const getLoadDetailSuccess = createAction(
  '[API] Get Load Load Detail Success',
  props<{
    load: any
  }>()
);

export const getLoadDetailFail = createAction(
  '[API] Get Load Detail Fail',
  props<{
    error: ErrorInAPI
  }>()
);

export const getLoadDetailById = createAction(
  '[API] Get Load Detail By Id',
  props<LoadById>()
);
export const getLoadDetailByIdSuccess = createAction(
  '[API] Get Load Detail By Id Success ',
  props<{
    loadDetail: LoadItem
  }>()
);
export const getLoadDetailByIdFail = createAction(
  '[API] Get Load Detail By Id Fail',
  props<{
    error: ErrorInAPI
  }>()
);

export const setImageDetailToRenderInModal = createAction(
  '[API] Get Seal Image Detail To Render In Modal',
  props<{
    imageData: ImageData
  }>()
);

export const resetStateAfterOperation = createAction(
  '[API] Reset State After Operation'
);

export const resetStateSuccess = createAction('[API] Reset Load State Success');

export const updateLoad = createAction(
  '[API] Update Load',
  props<ParamUpdateLoad>()
)

export const updateLoadSuccess = createAction(
  '[API] Update Load Success'
);

export const updateLoadError = createAction(
  '[API] Update Load Error',
  props<{ error: ErrorInAPI }>()
);

export const LoadActions = {
  loadLoadError,
  loadLoadSuccess,
  loadLoads,
  loadLoadWithSearch,
  setActiveTab,
  getLoadDetail,
  getLoadDetailSuccess,
  getLoadDetailFail,
  getLoadDetailById,
  getLoadDetailByIdSuccess,
  getLoadDetailByIdFail,
  setImageDetailToRenderInModal,
  resetStateAfterOperation,
  resetStateSuccess,
  updateLoad,
  updateLoadSuccess,
  updateLoadError
};
